:root{
    --color-blue-main: #3030D0;
    --color-gray-main: #F4F4FF;
    --color-white-main: #F4F4FF;
    --color-black-main: #000;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
}
header{
    z-index: 2;
}
.logo{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.logo img{
    width: 55px;
    height: 55px;
}
.logo p{
    margin-left: 2px;
    font-size: 16px;
    color: #181818;
    font-weight: 500;
    line-height: 17px;
    font-style: italic
}
main{
    flex: 1 0 auto !important;
    border-radius: 0.75rem;
    padding: 2rem;
    margin-top: 2rem;
    width: 100%;
}
main h1{
    text-align: center;
    font-weight: 600;
    color: var(--color-black-main);
}
.form-login{
    width: 600px;
    margin: 0 auto;
}

.form {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    background-color: #fff;
    display: block;
    padding: 1rem;
    max-width: 350px;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .form.form-cadastro{
    top: 10%;
    max-width: 380px;
  }
  .form-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    text-align: center;
    color: #000;
  }

  .input-container {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .input-container input, .form button {
    outline: none;
    border: 1px solid #e5e7eb;
    margin: 8px 0;
  }

  .input-container input,.input-container textarea, .input-container select {
    background-color: #fff;
    padding: 1rem;
    padding-left: 2.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 95%;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }

.input-container span {
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 1.5rem;
    padding-right: 1rem;
    place-content: center;
}
  .submit {
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #4F46E5;
    color: #ffffff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    width: 95%;
    margin: 10px auto !important;
    border-radius: 0.5rem;
    text-transform: uppercase;
  }
.submit:hover {
    text-decoration: none;
    color: #fff;
}
  .signup-link {
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
  }

  .esquecer-senha{
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
  }

  .signup-link a {
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    main{
        flex: none !important;
    }
    .form.form-cadastro{
        top: 0 !important;
        position: static !important;
        max-width: 380px !important;
    }
    .form-login{
        max-width: 380px !important;
    }
}
